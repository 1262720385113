import { Injectable, OnDestroy } from '@angular/core';
import { Rock } from '@entities/rock';
import { Goal } from '@entities/goal';
import { Store, select } from '@ngrx/store';
import { State } from '@app/app.state';
import { GoalService } from '../services/goal.service';
import { RockService } from '../services/rock.service';
import { Observable, Subject } from 'rxjs';
import { evolveSelectors } from './evolve.state';
import { GoalActions } from './goal/goal.actions';
import * as ActionItemActions from './action-item/action-item.actions';
import * as RockActions from './rock/rock.actions';
import {
   getDepartments,
   getBusinessUnits,
   getDepartmentFunctions,
} from '@app/org-builder/state/organization.state';
import { Department } from '@entities/department';
import { DepartmentService } from '@app/org-builder/services/department.service';
import * as OrganizationActions from '@app/org-builder/state/organization.actions';
import * as TeamActions from '@app/team/state/team.actions';
import { TeamMember } from '@entities/team-member';
import { getTeamMembers } from '@app/team/state/team.state';
import { ActionItem } from '@entities/action-item';
import { ActionItemService } from '../services/action-item.service';
import { TeamMemberService } from '@app/team/services/team-member.service';
import { DialogService } from '@app/shared/services/dialog.service';
import { FilterValues } from '@app/shared/interfaces/filter.interfaces';
import { takeUntil } from 'rxjs/operators';
import { Sort } from '@angular/material/sort';
import { DepartmentFunction } from '@entities/department-function';

@Injectable()
export class EvolveFacade implements OnDestroy {
   goals$: Observable<Goal[]> = this.store.pipe(select(evolveSelectors.getGoals));
   selectedGoal$: Observable<Goal> = this.store.pipe(select(evolveSelectors.getSelectedGoal));
   goalEditIds$: Observable<string[]> = this.store.pipe(select(evolveSelectors.getGoalsEditIds));
   organizationGoals$: Observable<Goal[]> = this.store.pipe(
      select(evolveSelectors.getOrganizationGoals)
   );
   organizationGoalsFilter$: Observable<FilterValues> = this.store.pipe(
      select(evolveSelectors.getOrganizationGoalsFilter)
   );
   businessUnitGoals$: Observable<Goal[]> = this.store.pipe(
      select(evolveSelectors.getBusinessUnitGoals)
   );
   businessUnitGoalsFilter$: Observable<FilterValues> = this.store.pipe(
      select(evolveSelectors.getBusinessUnitGoalsFilter)
   );
   departmentGoals$: Observable<Goal[]> = this.store.pipe(
      select(evolveSelectors.getDepartmentGoals)
   );
   departmentGoalsFilter$: Observable<FilterValues> = this.store.pipe(
      select(evolveSelectors.getDepartmentGoalsFilter)
   );
   goalsActiveTab$: Observable<number> = this.store.pipe(select(evolveSelectors.getGoalsActiveTab));

   rocks$: Observable<Rock[]> = this.store.pipe(select(evolveSelectors.getRocks));
   selectedRock$: Observable<Rock> = this.store.pipe(select(evolveSelectors.getSelectedRock));
   rocksFilter$: Observable<FilterValues> = this.store.pipe(select(evolveSelectors.getRocksFilter));
   rocksActiveTab$: Observable<number> = this.store.pipe(select(evolveSelectors.getRocksActiveTab));
   rocksVisibleColumns$: Observable<string[]> = this.store.pipe(
      select(evolveSelectors.getRocksVisibleColumns)
   );
   rocksSort$: Observable<Sort> = this.store.pipe(select(evolveSelectors.getRocksSort));

   actionItems$: Observable<ActionItem[]> = this.store.pipe(select(evolveSelectors.getActionItems));
   selectedActionItem$: Observable<ActionItem> = this.store.pipe(
      select(evolveSelectors.getSelectedActionItem)
   );
   actionItemsFilter$: Observable<FilterValues> = this.store.pipe(
      select(evolveSelectors.getActionItemsFilter)
   );
   actionItemsVisibleColumns$: Observable<string[]> = this.store.pipe(
      select(evolveSelectors.getActionItemsVisibleColumns)
   );
   actionItemsSort$: Observable<Sort> = this.store.pipe(select(evolveSelectors.getActionsSort));

   businessUnits$ = this.store.pipe(select(getBusinessUnits));
   departments$: Observable<Department[]> = this.store.pipe(select(getDepartments));
   departmentFunctions$: Observable<DepartmentFunction[]> = this.store.pipe(
      select(getDepartmentFunctions)
   );
   teamMembers$: Observable<TeamMember[]> = this.store.pipe(select(getTeamMembers));
   private destroyed$ = new Subject<void>();

   constructor(
      private store: Store<State>,
      private goalService: GoalService,
      private rockService: RockService,
      private actionItemService: ActionItemService,
      private departmentService: DepartmentService,
      private teamMemberService: TeamMemberService,
      private dialogService: DialogService
   ) {
      this.goalService.entities$.pipe(takeUntil(this.destroyed$)).subscribe((goals) => {
         this.store.dispatch(GoalActions.GoalsUpdated({ goals }));
      });
      this.rockService.entities$.pipe(takeUntil(this.destroyed$)).subscribe((rocks) => {
         this.store.dispatch(RockActions.RocksUpdated({ rocks }));
      });
      this.departmentService.entities$.pipe(takeUntil(this.destroyed$)).subscribe((departments) => {
         this.store.dispatch(OrganizationActions.DepartmentsUpdated({ departments }));
      });
      this.actionItemService.entities$.pipe(takeUntil(this.destroyed$)).subscribe((actionItems) => {
         this.store.dispatch(ActionItemActions.ActionItemsUpdated({ actionItems }));
      });
      this.teamMemberService.entities$.pipe(takeUntil(this.destroyed$)).subscribe((teamMembers) => {
         this.store.dispatch(TeamActions.TeamMembersUpdated({ teamMembers }));
      });
   }

   ngOnDestroy() {
      this.destroyed$.next();
      this.destroyed$.complete();
   }

   addGoal(featureFlag: boolean = true) {
      this.store.dispatch(GoalActions.AddGoal({ featureFlag }));
   }

   editGoal(goal: Goal, featureFlag: boolean = true) {
      this.store.dispatch(GoalActions.EditGoal({ goal, featureFlag }));
   }

   selectGoal(goalId: string) {
      this.store.dispatch(GoalActions.SelectGoal({ goalId }));
   }

   saveGoal(goal: Goal, featureFlag: boolean = false) {
      this.store.dispatch(GoalActions.SaveGoal({ goal, featureFlag }));
   }

   cancelEditGoal(goal: Goal) {
      this.store.dispatch(GoalActions.HideEditGoal({ goalId: goal.id }));
   }

   deleteGoal(goal: Goal) {
      this.dialogService
         .showConfirmDialog({
            title: 'Delete Goal?',
            message: 'Are you sure you want to delete this goal?',
            confirm: 'Yes, Delete',
            deny: 'No, Go Back',
         })
         .afterClosed()
         .subscribe((result) => {
            if (result) {
               this.store.dispatch(GoalActions.DeleteGoal({ goal }));
            }
         });
   }

   setGoalsActiveTab(activeTab: number) {
      this.store.dispatch(GoalActions.SetGoalsActiveTab({ activeTab }));
   }

   setOrgGoalsFilter(filterValues: FilterValues) {
      this.store.dispatch(GoalActions.SetOrganizationGoalsFilter({ filterValues }));
   }

   setBusinessUnitGoalsFilter(filterValues: FilterValues) {
      this.store.dispatch(GoalActions.SetBusinessUnitGoalsFilter({ filterValues }));
   }

   setDepartmentGoalsFilter(filterValues: FilterValues) {
      this.store.dispatch(GoalActions.SetDepartmentGoalsFilter({ filterValues }));
   }

   addRock() {
      this.store.dispatch(RockActions.AddRock());
   }

   editRock(rock: Rock) {
      this.store.dispatch(RockActions.EditRock({ rock }));
   }

   duplicateRock(rock: Rock) {
      this.store.dispatch(RockActions.DuplicateRock({ rock }));
   }

   saveRock(rock: Rock, preventNavigation: boolean = false) {
      if (rock.id) {
         this.store.dispatch(RockActions.SaveRock({ rock }));
      } else {
         this.store.dispatch(RockActions.CreateRock({ rock, preventNavigation }));
      }
   }

   selectRock(rockId: string) {
      this.store.dispatch(RockActions.SelectRock({ rockId }));
   }

   cancelEditRock(rock: Rock) {}

   deleteRock(rock: Rock) {
      this.dialogService
         .showConfirmDialog({
            title: 'Delete Rock?',
            message: 'Are you sure you want to delete this rock?',
            confirm: 'Yes, Delete',
            deny: 'No, Go Back',
         })
         .afterClosed()
         .subscribe((result) => {
            if (result) {
               this.store.dispatch(RockActions.DeleteRock({ rock }));
            }
         });
   }

   updateRocksFilter(filter: FilterValues) {
      this.store.dispatch(RockActions.UpdateRockFilterValues({ filter }));
   }

   setRocksActiveTab(activeTab: number) {
      this.store.dispatch(RockActions.SetActiveTab({ activeTab }));
   }

   setRocksDisplayedColumns(columns: string[]) {
      this.store.dispatch(RockActions.SetVisibleColumns({ columns }));
   }

   setRocksSort(sort: Sort) {
      this.store.dispatch(RockActions.SetSort({ sort }));
   }

   addActionItem() {
      this.store.dispatch(ActionItemActions.AddActionItem());
   }

   editActionItem(actionItem: ActionItem) {
      this.store.dispatch(ActionItemActions.EditActionItem({ actionItemId: actionItem.id }));
   }

   selectActionItem(actionItemId: string) {
      this.store.dispatch(ActionItemActions.SelectActionItem({ actionItemId }));
   }

   saveActionItem(actionItem: ActionItem, preventNavigation: boolean) {
      this.store.dispatch(ActionItemActions.SaveActionItem({ actionItem, preventNavigation }));
   }

   duplicateActionItem(actionItem: ActionItem) {
      this.store.dispatch(ActionItemActions.DuplicateActionItem({ actionItem }));
   }

   cancelEditActionItem(actionItem: ActionItem) {
      this.store.dispatch(ActionItemActions.HideEditActionItem({ actionItemId: actionItem.id }));
   }

   deleteActionItem(actionItem: ActionItem) {
      this.dialogService
         .showConfirmDialog({
            title: 'Delete ActionItem?',
            message: 'Are you sure you want to delete this action?',
            confirm: 'Yes, Delete',
            deny: 'No, Go Back',
         })
         .afterClosed()
         .subscribe((result) => {
            if (result) {
               this.store.dispatch(ActionItemActions.DeleteActionItem({ actionItem }));
            }
         });
   }
   updateActionItemsFilter(filter: FilterValues) {
      this.store.dispatch(ActionItemActions.ActionItemsFilterUpdated({ filter }));
   }

   setActionItemsDisplayedColumns(columns: string[]) {
      this.store.dispatch(ActionItemActions.SetVisibleColumns({ columns }));
   }

   setActionsSort(sort: Sort) {
      this.store.dispatch(ActionItemActions.SetSort({ sort }));
   }

   convertActionToRock(action: ActionItem, deleteAction: boolean) {
      this.store.dispatch(ActionItemActions.ConvertActionToRock({ action, deleteAction }));
   }
}
