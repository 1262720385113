<form [formGroup]="form">
   <div class="cards">
      <mat-card class="info-card">
         <mat-card-content>
            <div class="rock-info-container">
               <div class="rock-info-col-left">
                  <div class="rock-info-row">
                     <app-department-autocomplete
                        placeholder="Department"
                        [idFormControl]="getControl('departmentId')"
                        [canEdit]="canEdit"
                        (optionSelected)="setDepartmentId($event)"
                     ></app-department-autocomplete>
                     <mat-form-field>
                        <mat-label>Function</mat-label>
                        <mat-select formControlName="departmentFunctionId">
                           <mat-option [value]="deptFn.id" *ngFor="let deptFn of visibleDeptFns">
                              {{ deptFn.name }}
                           </mat-option>
                        </mat-select>
                     </mat-form-field>
                     <mat-form-field>
                        <mat-label>Status</mat-label>
                        <mat-select formControlName="status" required>
                           <mat-option [value]="status" *ngFor="let status of statusKeys">
                              {{ rockStatus[status] }}
                           </mat-option>
                        </mat-select>
                     </mat-form-field>
                  </div>
                  <div class="rock-info-row">
                     <mat-form-field>
                        <mat-label>Goal</mat-label>
                        <mat-select formControlName="goalId">
                           <mat-option *ngFor="let goal of goals" [value]="goal.id">
                              {{ goal.name }}
                           </mat-option>
                        </mat-select>
                     </mat-form-field>
                     <app-team-member-autocomplete
                        placeholder="Assigned To"
                        [idFormControl]="getControl('assigneeId')"
                        [canEdit]="canEdit"
                        (optionSelected)="setAssignedToId($event)"
                     ></app-team-member-autocomplete>
                  </div>
                  <ng-container *ngIf="canEdit; else noEdit">
                     <div class="description">
                        <mat-form-field class="fill-height">
                           <mat-label *ngIf="form.value.description">Description</mat-label>
                           <textarea
                              class="editor"
                              matInput
                              type="text"
                              formControlName="description"
                              [froalaEditor]="editorConfig"
                           ></textarea>
                        </mat-form-field>
                     </div>
                  </ng-container>
                  <ng-template #noEdit>
                     <div class="description" [innerHTML]="form.value.description"></div>
                  </ng-template>
               </div>
            </div>
         </mat-card-content>
      </mat-card>
      <div class="dates-card">
         <mat-card>
            <mat-card-title>Dates</mat-card-title>
            <mat-card-content>
               <div class="rock-info-col-right">
                  <app-quarterpicker
                     [label]="'Quarter'"
                     [formCtrl]="getControl('quarter')"
                     [required]="true"
                     [canEdit]="canEdit"
                  ></app-quarterpicker>
                  <mat-form-field>
                     <input
                        matInput
                        formControlName="approvedDate"
                        placeholder="Approved Date"
                        [matDatepicker]="approvedDatePicker"
                        (dateChange)="setDate($event, 'approvedDate')"
                        data-test-id="approved-date"
                     />
                     <ng-container *ngIf="canEdit">
                        <app-datepicker-clear
                           class="datepicker-clear"
                           [for]="approvedDatePicker"
                        ></app-datepicker-clear>
                        <mat-datepicker-toggle
                           matSuffix
                           [for]="approvedDatePicker"
                        ></mat-datepicker-toggle>
                     </ng-container>
                     <mat-datepicker #approvedDatePicker></mat-datepicker>
                  </mat-form-field>
                  <mat-form-field>
                     <input
                        matInput
                        formControlName="startDate"
                        placeholder="Start Date"
                        [matDatepicker]="startDatePicker"
                        (dateChange)="setDate($event, 'startDate')"
                     />
                     <ng-container *ngIf="canEdit">
                        <app-datepicker-clear
                           class="datepicker-clear"
                           [for]="startDatePicker"
                        ></app-datepicker-clear>
                        <mat-datepicker-toggle
                           matSuffix
                           [for]="startDatePicker"
                        ></mat-datepicker-toggle>
                     </ng-container>
                     <mat-datepicker #startDatePicker></mat-datepicker>
                  </mat-form-field>
                  <mat-form-field>
                     <input
                        matInput
                        formControlName="targetDate"
                        placeholder="Target Date"
                        [matDatepicker]="targetDatePicker"
                        (dateChange)="setDate($event, 'targetDate')"
                     />
                     <ng-container *ngIf="canEdit">
                        <app-datepicker-clear
                           class="datepicker-clear"
                           [for]="targetDatePicker"
                        ></app-datepicker-clear>
                        <mat-datepicker-toggle
                           matSuffix
                           [for]="targetDatePicker"
                        ></mat-datepicker-toggle>
                     </ng-container>
                     <mat-datepicker #targetDatePicker></mat-datepicker>
                  </mat-form-field>
                  <mat-form-field>
                     <input
                        matInput
                        formControlName="completedDate"
                        placeholder="Completed Date"
                        [matDatepicker]="completedDatePicker"
                        (dateChange)="setDate($event, 'completedDate')"
                     />
                     <ng-container *ngIf="canEdit">
                        <app-datepicker-clear
                           class="datepicker-clear"
                           [for]="completedDatePicker"
                        ></app-datepicker-clear>
                        <mat-datepicker-toggle
                           matSuffix
                           [for]="completedDatePicker"
                        ></mat-datepicker-toggle>
                     </ng-container>
                     <mat-datepicker #completedDatePicker></mat-datepicker>
                  </mat-form-field>
               </div>
            </mat-card-content>
         </mat-card>
      </div>
   </div>
</form>
